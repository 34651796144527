//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

/* nunito-regular */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: regular;
  src: url("//lib.baomitu.com/fonts/nunito/nunito-regular.eot"); /* IE9 Compat Modes */
  src: local("Nunito"), local("Nunito-Normal"),
    url("//lib.baomitu.com/fonts/nunito/nunito-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("//lib.baomitu.com/fonts/nunito/nunito-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("//lib.baomitu.com/fonts/nunito/nunito-regular.woff") format("woff"),
    /* Modern Browsers */ url("//lib.baomitu.com/fonts/nunito/nunito-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("//lib.baomitu.com/fonts/nunito/nunito-regular.svg#Nunito") format("svg"); /* Legacy iOS */
}

/* nunito-italic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: regular;
  src: url("//lib.baomitu.com/fonts/nunito/nunito-italic.eot"); /* IE9 Compat Modes */
  src: local("Nunito"), local("Nunito-Italic"),
    url("//lib.baomitu.com/fonts/nunito/nunito-italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("//lib.baomitu.com/fonts/nunito/nunito-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("//lib.baomitu.com/fonts/nunito/nunito-italic.woff") format("woff"),
    /* Modern Browsers */ url("//lib.baomitu.com/fonts/nunito/nunito-italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("//lib.baomitu.com/fonts/nunito/nunito-italic.svg#Nunito") format("svg"); /* Legacy iOS */
}

/* nunito-600 */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  src: url("//lib.baomitu.com/fonts/nunito/nunito-600.eot"); /* IE9 Compat Modes */
  src: local("Nunito"), local("Nunito-Normal"),
    url("//lib.baomitu.com/fonts/nunito/nunito-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("//lib.baomitu.com/fonts/nunito/nunito-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("//lib.baomitu.com/fonts/nunito/nunito-600.woff") format("woff"),
    /* Modern Browsers */ url("//lib.baomitu.com/fonts/nunito/nunito-600.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("//lib.baomitu.com/fonts/nunito/nunito-600.svg#Nunito") format("svg"); /* Legacy iOS */
}

/* nunito-700 */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  src: url("//lib.baomitu.com/fonts/nunito/nunito-700.eot"); /* IE9 Compat Modes */
  src: local("Nunito"), local("Nunito-Normal"),
    url("//lib.baomitu.com/fonts/nunito/nunito-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("//lib.baomitu.com/fonts/nunito/nunito-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("//lib.baomitu.com/fonts/nunito/nunito-700.woff") format("woff"),
    /* Modern Browsers */ url("//lib.baomitu.com/fonts/nunito/nunito-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("//lib.baomitu.com/fonts/nunito/nunito-700.svg#Nunito") format("svg"); /* Legacy iOS */
}
